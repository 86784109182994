<template>
  <v-data-table
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="options"
    calculate-widths
    height="calc(100vh - 254px)"
    hide-default-footer
    :server-items-length="1"
    disable-filtering
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar dense class="custom-toolbar" flat>
        <v-toolbar-title style="font-size: 15px"
          >Tổng: {{ pagination.total }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('handle-create')"
          class="mx-2"
          x-small
          fab
          dark
          color="indigo"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <template v-slot:item.logo="{ item }">
      <img :src="'storage/' + item.logo" width="100%" alt="" />
    </template>
    <template v-slot:item.description="{ item }">
      <p class="description my-0 mt-1">
        {{ item.description }}
      </p>
    </template>
    <template v-slot:item.businessAreas="{ item }">
      <p class="my-0 mt-1" v-for="ba in item.businessAreas" :key="ba.id">
        {{ ba.name }}
      </p>
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-chip v-if="!item.isActive" color="warning" dark>
        Không hoạt động
      </v-chip>

      <v-chip v-else color="success" dark>
        Hoạt động
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small @click="edit(item.code)" fab dark color="primary">
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" class="mt-3" @click="$emit('handle-reset')"
        >Tải lại</v-btn
      >
    </template>
  </v-data-table>
</template>
<script>
import dataTableMixin from "@/mixins/crud/data-table";
export default {
  mixins: [dataTableMixin()],
  computed: {
    headers() {
      return [
        {
          text: "Logo",
          value: "logo",
          sortable: false,
          width: 70,
          align: "center"
        },
        { text: "Tên viết tắt", value: "abbreviations", sortable: false },
        { text: "Tên", value: "name", sortable: false },
        { text: "Mô tả", value: "description", sortable: false, width: 400 },
        {
          text: "Linh vực kinh doanh",
          value: "businessAreas",
          sortable: false
        },
        { text: "Link website", value: "website", sortable: false },
        { text: "Trạng thái", value: "isActive", sortable: false },
        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false
        }
      ];
    }
  },
  methods: {
    edit(code) {
      this.$router.push(`/edit/${code}/information`);
    }
  }
};
</script>
<style lang="scss">
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
