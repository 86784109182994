<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="form.name"
                  label="Tên tổ chức (*)"
                  dense
                  :rules="[(v) => !!v || 'Bạn chưa nhập tên tổ chức']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="form.abbreviations"
                  label="Tên viết tắt (*)"
                  dense
                  :rules="[(v) => !!v || 'Bạn chưa nhập tên viết tắt']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.businessAreas"
                  label="Lĩnh vực kinh doanh (*)"
                  class="mt-5"
                  :items="options.businessAreas"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    (v) =>
                      !!v.length > 0 || 'Bạn chưa chọn lĩnh vực kinh doanh',
                  ]"
                  multiple
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  label="Logo"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon=""
                  append-icon="mdi-file-outline"
                  @change="file = $event"
                  :value="file"
                ></v-file-input
              ></v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  label="Mô tả"
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.website"
                  label="Link website"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Huỷ</v-btn>
        <v-btn v-if="!editing" color="blue darken-1" text @click="createData"
          >Thêm</v-btn
        >
        <v-btn v-else color="blue darken-1" text @click="updateData"
          >Cập nhật</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { store, update } from "@/api/organization";
import dialogMixin from "@/mixins/crud/dialog";
//validator import
import {} from "validator";
export default {
  mixins: [dialogMixin(store, update)],
  computed: {
    title() {
      return !this.editing ? "Đăng kí thông tin tổ chức" : "Cập nhật";
    },
  },
  watch: {
    showDialog(val) {
      if (val) this.file = null;
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    async createData() {
      try {
        await this.$refs.form.validate();
        if (!this.valid) return;
        this.loading = true;
        const form = new FormData();
        for (const field in this.form) {
          if (field === "businessAreas") {
            for (const b of this.form.businessAreas) {
              form.append("businessAreas[]", b);
            }
            continue;
          }
          if (this.form[field] !== undefined)
            form.append(field, this.form[field]);
        }
        if (this.file) form.append("logo", this.file);
        await store(form);
        this.reload();
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
