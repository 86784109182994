var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders(),"items":_vm.tableData,"disable-pagination":"","fixed-header":"","options":_vm.options,"calculate-widths":"","height":"calc(100vh - 254px)","hide-default-footer":"","server-items-length":1,"disable-filtering":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"custom-toolbar",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticStyle:{"font-size":"15px"}},[_vm._v("Tổng: "+_vm._s(_vm.pagination.total))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"x-small":"","fab":"","dark":"","color":"indigo"},on:{"click":function($event){return _vm.$emit('handle-create')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":'storage/' + item.logo,"width":"100%","alt":""}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"description my-0 mt-1"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.businessAreas",fn:function(ref){
var item = ref.item;
return _vm._l((item.businessAreas),function(ba){return _c('p',{key:ba.id,staticClass:"my-0 mt-1"},[_vm._v(" "+_vm._s(ba.name)+" ")])})}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(!item.isActive)?_c('v-chip',{attrs:{"color":"warning","dark":""}},[_vm._v(" Không hoạt động ")]):_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" Hoạt động ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.edit(item.code)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('handle-reset')}}},[_vm._v("Tải lại")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }